img.page-logo {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    max-width: 200px;
    width: -webkit-fill-available;
}

div.login-form-card {
    background-color: #f1f1f1;
    margin-left: auto;
    margin-right: auto;
    color: black;
    border-radius: 10px;
    margin-top: 20px;
    border: 1px solid #b5b5b5;
    box-shadow: 3px 3px 10px 2px #7b7b7b;
    max-width: 600px;
    min-height: 100px;
    padding: 10px;
    width: -webkit-fill-available;
}

div.divider-o {
    max-width: 650px;
    width: -webkit-fill-available;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

div.divider-in-flex {
    width: -webkit-fill-available;
}

div.login-title {
    font-size: 50px;
    font-weight: 700;
    margin: auto;
    text-align: center;
}

div.form-login-card {
    margin: 5px;
}

*, *:before, *:after {
    box-sizing: border-box;
}

.g-sign-in-button {
    margin: 10px;
    display: inline-block;
    width: 240px;
    height: 50px;
    background-color: #333;
    color: #fff;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: background-color .218s, border-color .218s, box-shadow .218s;
}

.g-sign-in-button:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 0 3px 3px #dddddd;
    box-shadow: 0 0 3px 3px #b9b9b9;
}

.g-sign-in-button:active {
    background-color: #333;
    transition: background-color 0.2s;
}

.g-sign-in-button .content-wrapper {
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
}

.g-sign-in-button img {
    width: 25px;
    height: 25px;
}

.g-sign-in-button .logo-wrapper {
    padding: 12px;
    background: #fff;
    width: 48px;
    height: 100%;
    border-radius: 1px;
    display: inline-block;
}

.logo-wrapper-apple {
    padding: 2px;
    background: #fff;
    width: 48px;
    height: 100%;
    border-radius: 1px;
    display: inline-block;
}

.g-sign-in-button .text-container {
    font-family: Roboto,arial,sans-serif;
    font-weight: 500;
    letter-spacing: .21px;
    font-size: 16px;
    line-height: 48px;
    vertical-align: top;
    border: none;
    display: inline-block;
    text-align: center;
    width: 180px;
}

img.apple-icon {
    width: 46px;
    height: 40px;
}

div.options-text {
    color: black;
    font-size: 12px;
}

span.options-text {
    color: black;
    font-size: 12px;
    font-weight: 600;
}

span.options-text:hover {
    cursor: pointer;
}