@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

ul.navbar {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #708090;
    align-items: center;
    padding: 0px 5px;
    height: 64px;
    z-index: 4;
    position: fixed;
    top: 0;
    width: -webkit-fill-available;
    flex-wrap: nowrap;
  }

.logo {
    height: 50px;
    border-radius: 10px;
    background-color: transparent;
    padding: 2px;
    object-fit: scale-down;
}

div.button {
    display: flex;
    align-items: center;
    padding: 4px;
    height: inherit;
}

a.navbar {
    color: white;
    text-align: center;
    padding: 3px;
    text-decoration: none;
    align-items: center;
    font-size: 26;
}

.button:hover {
    background-color: #36454f;
    cursor: pointer;
    border-radius: 10px;
}

#no-hover:hover{
    background-color: transparent;
}

#right {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.page-title {
    font-size: 30px;
    font-weight: 900;
}

.cart-drawer {
    background-color: #708090;
    width: 300px;
    height: -webkit-fill-available;
    display: flex;
    flex-flow: column;
}

div.cart-course-card {
    font-family: 'Ubuntu';
}

div.cart-title {
    font-size: 20px;
    font-weight: 700;
}

div.cart-price {
    display: flex;
}

